<template>
  <div class="card card-authentication">
    <validation-observer
      tag="form"
      class="card-body"
      @submit.native.prevent
      ref="validation"
    >
      <img class="logo" src="@/assets/img/logo/primary.svg" alt="Codium" />

      <h1 class="mb-4">2-Factor Authentication Opt-in</h1>

      <p class="text-muted">
        Using a second authentication method helps keep your account secure.
      </p>

      <validation-provider
        tag="div"
        class="mb-4"
        vid="two_factor_required"
        name="two factor"
        rules="required"
      >
        <p class="label">Use 2-factor when logging in?</p>

        <c-radio
          name="2fa-opt-in"
          id="2fa-yes"
          inline
          v-model="twoFactorRequired"
          :value="true"
        >
          Yes
        </c-radio>

        <c-radio
          name="2fa-opt-in"
          id="2fa-no"
          inline
          v-model="twoFactorRequired"
          :value="false"
        >
          No
        </c-radio>

        <c-help-block/>
      </validation-provider>

      <validation-provider
        v-if="twoFactorRequired"
        tag="div"
        class="mb-4"
        name="two factor method"
        rules="required"
      >
        <p class="label">
          Set your preferred method for 2-factor authentication.
        </p>

        <c-radio
          inline
          name="2fa-method-preferred"
          id="2fa-method-email"
          v-model="twoFactorMethodPreferred"
          value="email"
        >
          Email
        </c-radio>

        <c-radio
          inline
          name="2fa-method-preferred"
          id="2fa-method-app"
          v-model="twoFactorMethodPreferred"
          value="app"
        >
          Authenticator App
        </c-radio>

        <c-help-block/>
      </validation-provider>

      <div class="row">
        <div class="col-auto text-right d-flex align-items-center">
          <router-link :to="{ name: 'logout' }" class="small text-muted">
            Sign Out
          </router-link>
        </div>
        <div class="text-end col">
          <c-button @click="save" validate>
            Next
          </c-button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import { authMethods } from '@/vuex/helpers'

export default {
  data () {
    return {
      twoFactorRequired: null,
      twoFactorMethodPreferred: null
    }
  },

  methods: {
    ...authMethods,

    async save () {
      if (this.twoFactorRequired === false) {
        this.twoFactorMethodPreferred = null
      }

      await this.updateTwoFactor({
        payload: {
          two_factor_required: this.twoFactorRequired,
          two_factor_method_preferred: this.twoFactorMethodPreferred
        }
      })

      switch (this.twoFactorMethodPreferred) {
        case 'app':
          return this.$router.push({ name: 'two-factor.setup.app' })
        case 'email':
          return this.$router.push({ name: 'two-factor.setup.email' })
        default:
          this.$router.push({ name: 'dashboard' })
      }
    }

  }
}
</script>
