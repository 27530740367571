<template>
  <c-input
    v-c-focus
    type="text"
    placeholder="Enter code"
    name="code"
    v-bind="{ value }"
    :class="invalidClass"
    @input="updateValue"
  />
</template>

<script>
import { useIsInvalid } from '@chameleon/chameleon-vue'

export default {
  mixins: [useIsInvalid],

  props: {
    value: String
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value.replace(/\s+/g, ''))
    }
  }
}
</script>
