<template>
  <div class="card card-authentication">
    <validation-observer tag="form" class="card-body" @submit.prevent ref="validation">
      <img class="logo" src="@/assets/img/logo/primary.svg" alt="Codium" />

      <h1 class="mb-4">Set Password</h1>

      <c-alert v-if="error" variant="danger">
        {{ error }}
      </c-alert>

      <validation-provider
        tag="div"
        class="mb-3"
        name="password"
        rules="required|password"
      >
        <label>New Password</label>
        <c-input v-model="password" type="password" />
        <c-help-block />
      </validation-provider>

      <validation-provider
        tag="div"
        class="mb-3"
        vid="password_confirmation"
        name="password confirmation"
        :rules="{ required: true, password: true, is: password }"
      >
        <label>Repeat New Password</label>
        <c-input v-model="passwordConfirmation" type="password" />
        <c-help-block />
      </validation-provider>

      <password-strength-bar
        v-bind="{ password, label: 'Password Strength' }"
        class="mb-3"
      />

      <div class="row">
        <div class="col-auto text-end d-flex align-items-center">
          <router-link class="small text-muted" :to="{ name: 'logout' }">
            Sign Out.
          </router-link>
        </div>
        <div class="col text-end">
          <c-button type="submit" validate @click="setPassword">
            Set New Password
          </c-button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { http } from '@/utils'
import { toast } from '@chameleon/chameleon-vue'
import PasswordStrengthBar from '@/components/PasswordStrengthBar'

export default {
  components: {
    PasswordStrengthBar
  },

  data () {
    return {
      error: null,
      password: '',
      passwordConfirmation: null
    }
  },

  computed: {
    email () {
      return this.$route.query.email
    },

    token () {
      return this.$route.params.token
    }
  },

  methods: {
    async setPassword () {
      try {
        const payload = {
          email: this.email,
          token: this.token,
          password: this.password,
          password_confirmation: this.passwordConfirmation
        }

        await http.post('/password/reset', payload)

        toast('Your password has been reset.', 'success')

        this.$router.push({ name: 'login' })
      } catch (error) {
        if (error.response && error.response.status !== 422) {
          this.error = 'Something went wrong, please try again.'
          return
        }
        if (error.response && error.response.status === 422 && error.response.data.errors.generic) {
          this.error = error.response.data.errors.generic[0]
          return
        }
        throw error
      }
    }
  }

}
</script>
