<template>
  <div class="card card-authentication">
    <validation-observer
      tag="form"
      class="card-body"
      @submit.native.prevent
      ref="validation"
    >
      <img class="logo" src="@/assets/img/logo/primary.svg" alt="Codium" />

      <h1>Log In.</h1>

      <div class="mb-3 text-danger" v-if="tooManyAttemptsError">
        {{ tooManyAttemptsError }}.
      </div>

      <validation-provider
        tag="div"
        class="mb-3"
        name="email"
        rules="required|email"
      >
        <label>Email Address</label>
        <c-input
          v-model="email"
          type="text"
        />
        <c-help-block/>
      </validation-provider>

      <validation-provider
        tag="div"
        class="mb-3"
        name="password"
        rules="required"
      >
        <label>Password</label>
        <c-input
          v-model="password"
          type="password"
        />
        <c-help-block/>
      </validation-provider>

      <div class="row">
        <div class="col-auto text-right d-flex align-items-center">
          <router-link
            :to="{ name: 'forgot-password' }"
            class="small text-muted"
            >Forgot Password?</router-link
          >
        </div>
        <div class="col text-end">
          <c-button type="submit" class="btn btn-primary" @click="attempt" validate>
            Log In
          </c-button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { routeNavigationFailure } from '@codium/codium-vue'
import { toast } from '@chameleon/chameleon-vue'
import { appComputed, appMethods, authMethods } from '@/vuex/helpers'

export default {
  data () {
    return {
      email: null,
      password: null,
      csrfTokenExpired: false,
      tooManyAttemptsError: null
    }
  },

  computed: {
    ...appComputed
  },

  methods: {
    ...appMethods,
    ...authMethods,

    async attempt () {
      try {
        await this.login({ email: this.email, password: this.password })

        this.$router.replace({ name: 'dashboard' }).catch(routeNavigationFailure)
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else if (error.response && error.response.status === 429) {
          this.tooManyAttemptsError = error.response.data.errors.email[0]
        } else {
          throw error
        }
      }
    }
  }
}
</script>
