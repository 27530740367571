<template>
  <div class="card card-authentication">
    <div class="card-body">
      <router-link :to="{ name: 'login' }">
        <img
          class="logo"
          src="@/assets/img/logo/primary.svg"
          alt="Codium"
        />
      </router-link>
      <div class="row">
        <div class="col-auto">
          <div class="pb-2">
            <div class="swal-icon swal-icon--success mx-2">
              <div
                class="swal-icon--success__line swal-icon--success__line--long"
              ></div>
              <div
                class="swal-icon--success__line swal-icon--success__line--tip"
              ></div>
              <div class="swal-icon--success__ring"></div>
              <div class="swal-icon--success__hide-corners"></div>
            </div>
          </div>
        </div>
        <div class="col">
          <h1 class="mb-4">Email is already verified.</h1>

          <p class="mb-4">
            <template v-if="message">{{ message }}.</template>
          </p>

          <div class="text-right">
            <router-link :to="{ name: 'dashboard' }" class="btn btn-primary">Login</router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['message'],

  components: {
  }
}
</script>
