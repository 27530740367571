<template>
  <div class="card card-authentication">
    <validation-observer tag="form" class="card-body" @submit.native.prevent>
      <img class="logo" src="@/assets/img/logo/primary.svg" alt="Codium" />

      <template v-if="sent">
        <h1>Password Reset Sent</h1>

        <p class="text-muted">An email has been sent to <strong>{{ email }}</strong> with instructions for resetting your password if a matching user was found.</p>

        <div class="row">
          <div class="col-auto text-right d-flex align-items-center">
            <router-link :to="{ name: 'login' }" class="small text-muted"><i class="far fa-arrow-left"></i> Back to log in.</router-link>
          </div>
        </div>
      </template>

      <template v-else>
        <h1>Forgot Password?</h1>

        <p class="text-muted">If you have forgotten your password enter your email address below to get your password reset.</p>

        <validation-provider tag="div" class="mb-3" name="email" rules="required|email">
          <label>Email Address</label>
          <c-input type="text" v-model="email" />
          <c-help-block/>
        </validation-provider>

        <div class="row">
          <div class="col-auto text-right d-flex align-items-center">
            <router-link :to="{ name: 'login' }" class="small text-muted"><i class="far fa-arrow-left"></i> Back to log in.</router-link>
          </div>
          <div class="col text-end">
            <c-button type="submit" @click="next" validate>
              Next
            </c-button>
          </div>
        </div>
      </template>

    </validation-observer>
  </div>
</template>

<script>
import { authMethods } from '@/vuex/helpers'

export default {
  data () {
    return {
      email: null,
      sent: false
    }
  },

  methods: {
    ...authMethods,

    async next () {
      const { email } = this

      await this.forgotPassword({ email })
      this.sent = true
    }
  }
}
</script>
