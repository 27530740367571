<template>
  <div>
  </div>
</template>
<script>
import { call } from 'vuex-pathify'
import { toast } from '@chameleon/chameleon-vue'

export default {
  async created () {
    try {
      await this.logout()

      this.$router.replace({ name: 'login' })
    } catch (error) {
      toast('Oops, we were unable to log you out.', 'danger')

      this.$router.replace({ name: 'dashboard' })
    }
  },

  methods: {
    ...call('auth', ['logout'])
  }
}
</script>
