<template>
  <div class="card card-authentication">
    <validation-observer
      tag="form"
      class="card-body"
      @submit.prevent
      ref="validation"
    >
      <img src="@/assets/img/logo/primary.svg" alt="Codium" class="logo" />

      <h1>Setup App 2-Step Verification</h1>

      <h2 class="h4 mt-4">1. Add your shared key</h2>

      <p class="text-muted">
        Install the Google Authenticator, Authy app or other supported TOTP app
        on your iOS or Android device.
      </p>
      <p class="text-muted">
        Add your shared key by scanning or manually adding the code below.
      </p>

      <div class="row justify-content-center">
        <div class="col-auto border border-primary rounded p-3 text-center">
          <qrcode
            v-if="twoFactorProvisioningUri"
            :value="twoFactorProvisioningUri"
            :options="{ width: 128, margin: 0 }"
            class="mb-3"
          />
          <p class="text-muted text-center mb-0 small">
            Manual Code: <br />{{ twoFactorSecret }}
          </p>
        </div>
      </div>
      <h2 class="h4 mt-4">2. Enter a generated code</h2>
      <p class="text-muted">
        Once you have added the key to your device enter a generated code and
        verify to complete the setup process.
      </p>
      <validation-provider
        tag="div"
        class="mb-3"
        name="code"
        rules="required"
        mode="passive"
      >
        <label class="form-label">Verification Code</label>
        <two-factor-code-input v-model="code"/>
        <c-help-block />
      </validation-provider>
      <div class="row">
        <div class="col-auto text-right d-flex align-items-center">
          <router-link :to="{ name: 'logout' }" class="small text-muted">
            Sign Out
          </router-link>
        </div>
        <div class="col text-end">
          <c-button type="submit" @click="submit" validate>
            Verify Code
          </c-button>
        </div>
      </div>
    </validation-observer>
    <div class="card-footer border-top">
      <p class="small text-muted text-end">
        Can't access your code?<br />
        <span class="text-muted">
          Switch to: <a href="" @click.prevent="switchToEmail">Email</a>
        </span>
      </p>
    </div>
  </div>
</template>
<script>
import { authMethods, authComputed } from '@/vuex/helpers'
import { call } from 'vuex-pathify'
import Qrcode from '@chenfengyuan/vue-qrcode'
import { toast } from '@chameleon/chameleon-vue'
import TwoFactorCodeInput from '@/components/forms/TwoFactorCodeInput'

export default {
  components: {
    Qrcode,
    TwoFactorCodeInput
  },

  data () {
    return {
      code: null,
      error: null
    }
  },

  computed: authComputed,

  async created () {
    await this.getTwoFactorSecret()
  },

  methods: {
    updateTwoFactor: call('users/updateTwoFactor'),
    ...authMethods,

    async submit () {
      await this.verifyTwoFactorCode({ code: this.code, method: 'app', secret: this.twoFactorSecret })

      await this.authorize()

      toast('Your account is now protected by 2-Factor Authentication.', 'success')

      this.$router.push({ name: 'dashboard' })
    },

    async switchToEmail () {
      await this.updateTwoFactor({ id: this.$user.id, payload: { two_factor_required: true, two_factor_method_preferred: 'email' } })

      this.$router.push({ name: 'two-factor.setup.email' })
    }
  }
}
</script>
