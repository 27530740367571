<template>
  <fragment>
    <div class="card card-authentication" v-if="!verificationFailed && !accountAlreadyVerified">
      <validation-observer tag="form" class="card-body" @submit.prevent ref="validation">
        <img class="logo" src="@/assets/img/logo/primary.svg" alt="Codium" />

        <h1 class="mb-4">Finish Setting Up Your Account</h1>

        <!-- email -->
        <div class="mb-3" >
          <label>Email Address</label>
          <c-input v-model="email" disabled/>
        </div>

        <!-- password -->
        <validation-provider tag="div" class="mb-3" name="password" rules="required|password" >
          <label>Password</label>
          <c-input v-model="password" type="password" />
          <c-help-block />
        </validation-provider>

        <!-- repeat password -->
        <validation-provider
          tag="div"
          class="mb-3"
          vid="password_confirmation"
          name="password confirmation"
          :rules="{ required: true, password: true, is: password }"
        >
          <label>Repeat Password</label>
          <c-input v-model="passwordConfirmation" type="password" />
          <c-help-block />
        </validation-provider>

        <!-- password strength bar -->
        <password-strength-bar v-bind="{ password, label: 'Password Strength' }" class="mb-3" />

        <!-- terms and conditions -->
        <validation-provider tag="div" class="mb-3" name="terms and conditions" vid="agree_terms" :rules="{ required: { allowFalse: false } }">
          <c-checkbox v-model="agree_terms">
            I agree to the
            <router-link class="text-decoration-underline" :to="{ name: 'privacy-policy' }" target="_blank">privacy policy</router-link>
            and
            <router-link class="text-decoration-underline" :to="{ name: 'terms' }" target="_blank">terms and conditions</router-link>
              of use.
          </c-checkbox>
        </validation-provider>

        <div class="row">
          <!-- back to login -->
          <div class="col-auto text-end d-flex align-items-center">
            <a href="" @click.prevent="handleLogout" class="small text-muted"><i class="far fa-arrow-left"></i> Back to log in.</a>
          </div>
          <!-- submit -->
          <div class="col text-end">
            <c-button type="submit" validate @click="setupAccount">
              Finish Setup
            </c-button>
          </div>
        </div>
      </validation-observer>
    </div>
    <!-- verification failed -->
    <verification-failed v-if="verificationFailed" :error="verificationFailedError" />
    <account-already-verified v-if="accountAlreadyVerified" :message="alreadyVerifiedMessage" />
  </fragment>
</template>

<script>
import { http } from '@/utils'
import { toast } from '@chameleon/chameleon-vue'
import PasswordStrengthBar from '@/components/PasswordStrengthBar'
import { authComputed, authMethods } from '@/vuex/helpers'
import VerificationFailed from './AccountVerificationFailed'
import AccountAlreadyVerified from './AccountAlreadyVerified'

export default {
  props: {
    signature: String
  },
  components: {
    PasswordStrengthBar,
    VerificationFailed,
    AccountAlreadyVerified,
  },

  data () {
    return {
      agree_terms: false,
      password: '',
      passwordConfirmation: null,
      verificationFailed: false,
      verificationFailedError: null,
      accountAlreadyVerified: false,
      alreadyVerifiedMessage: null
    }
  },

  computed: {
    ...authComputed,

    email () {
      return this.$route.query.email ?? this.$user.email
    },

    token () {
      return this.$route.params.token
    },
  },

  async created () {
    if (this.$user.id) {
      return
    }
    try {
      // verify
      await http.post('/auth/verification', {
        signature: this.signature,
        expires: this.$route.query.expires,
        identifier: this.$route.query.identifier,
      }, {
        throwForbiddens: true
      })
      // login
      await this.authorize()
    } catch (error) {
      if (error.response && error.response.data.message !== 'Email is already verified') {
        this.verificationFailed = true
        this.verificationFailedError = error.response.data.message
      }
      this.accountAlreadyVerified = true
      this.alreadyVerifiedMessage = error.response.data.message
    }
  },

  methods: {
    ...authMethods,

    async handleLogout () {
      await this.logout()
      this.$router.push({ name: 'login' })
    },

    async setupAccount () {
      try {
        const payload = {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          agree_terms: this.agree_terms
        }
        await http.put('auth/setup-account', payload)
        await this.authorize()
        this.$router.push({ name: 'dashboard' })
      } catch (error) {
        if (error.response && error.response.status !== 422) {
          toast('Something went wrong trying to set your password', 'danger')
          return
        }

        throw error
      }
    }
  }

}
</script>
