<template>
  <div class="card card-authentication">
    <validation-observer tag="form" class="card-body" @submit.prevent>
      <img class="logo" src="@/assets/img/logo/primary.svg" alt="Codium" />

      <h1>Setup Email 2-Step Verification</h1>

      <p>An email has been sent to your email address.</p>

      <p>
        Please enter the verification code to complete the setup process.
      </p>

      <validation-provider
        tag="div"
        class="form-group mb-3"
        name="code"
        rules="required"
      >
        <label class="sr-only">Enter Code</label>
        <two-factor-code-input v-model="code"/>
        <c-help-block />
      </validation-provider>

      <div class="row">
        <div class="col-auto text-right d-flex align-items-center">
          <router-link :to="{ name: 'logout' }" class="small text-muted">
            Sign Out
          </router-link>
        </div>
        <div class="text-end col">
          <c-button type="submit" @click="submit" validate>
            Verify Code
          </c-button>
        </div>
      </div>
    </validation-observer>
    <div class="card-body border-top pb-2">
      <p class="small mb-0">Can't access your email?</p>

      <span class="text-muted small">
        <a href="" @click.prevent="switchToAuthenticatorApp">Authenticate via app.</a>
      </span>
    </div>
  </div>
</template>

<script>
import { authMethods } from '@/vuex/helpers'
import { call } from 'vuex-pathify'
import { routeNavigationFailure } from '@codium/codium-vue'
import { toast } from '@chameleon/chameleon-vue'
import TwoFactorCodeInput from '@/components/forms/TwoFactorCodeInput'

export default {
  components: {
    TwoFactorCodeInput
  },

  data () {
    return {
      code: null
    }
  },

  async created () {
    await this.sendTwoFactorEmailCode()
  },

  methods: {
    updateTwoFactor: call('users/updateTwoFactor'),
    ...authMethods,

    async switchToAuthenticatorApp () {
      await this.updateTwoFactor({ id: this.$user.id, payload: { two_factor_required: true, two_factor_method_preferred: 'app' } })

      this.$router.push({ name: 'two-factor.setup.app' })
    },

    async submit () {
      await this.verifyTwoFactorCode({ code: this.code, method: 'email' })

      await this.authorize()

      toast('Your account is now protected by 2-Factor Authentication.', 'success')

      this.$router.push({ name: 'dashboard' }).catch(routeNavigationFailure)
    }
  }
}
</script>
