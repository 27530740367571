<template>
  <div class="card card-authentication">
    <validation-observer
      tag="form"
      class="card-body"
      @submit.prevent
      ref="validation"
    >
      <img class="logo" src="@/assets/img/logo/primary.svg" alt="Codium" />

      <h1 class="mb-4">2-Step Verification</h1>

      <p class="text-muted" v-if="enabledMethodIsApp">
        Enter the 6-digit code provided by your authenticator app.
      </p>
      <p class="text-muted" v-else-if="enabledMethodIsEmail">
        Enter the 6-digit code sent to you by email.
      </p>

      <validation-provider
        tag="div"
        class="mb-3"
        name="code"
        rules="required"
        mode="passive"
      >
        <label class="sr-only">Verification Code</label>
        <two-factor-code-input v-model="code"/>
        <c-help-block />
      </validation-provider>
      <div class="row">
        <div class="col-auto text-end d-flex align-items-center">
          <router-link class="small text-muted" :to="{ name: 'logout' }">
            Sign Out.
          </router-link>
        </div>

        <div class="col text-end">
          <c-button type="submit" @click="submit" validate>
            Verify Code
          </c-button>
        </div>
      </div>
    </validation-observer>
    <div class="card-body border-top pb-2">
      <div class="row">
        <div
          v-if="enabledMethodIsEmail"
          class="col-auto d-flex align-items-center"
        >
          <p class="small text-muted mb-0">
            Code has not arrived?<br />
            <a href="" @click.prevent="emailTwoFactorCode">Send new code.</a>
          </p>
        </div>
        <div class="col text-end" v-if="preferredMethodIsApp">
          <p class="small z mb-0">
            <span class="text-muted" v-if="enabledMethodIsApp">
              Can't access your code?<br />
              <a href="" @click.prevent="emailTwoFactorCode">Authenticate via email.</a>
            </span>
            <span class="text-muted" v-else-if="enabledMethodIsEmail">
              Can't access your email?<br />
              <a href="" @click.prevent="method = 'app'">Authenticate via app.</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authMethods, authComputed } from '@/vuex/helpers'
import swal from 'sweetalert'
import TwoFactorCodeInput from '@/components/forms/TwoFactorCodeInput'

export default {
  components: {
    TwoFactorCodeInput
  },

  data () {
    return {
      code: null,
      method: null
    }
  },

  computed: {
    ...authComputed,

    enabledMethodIsApp () {
      return this.method === 'app'
    },

    enabledMethodIsEmail () {
      return this.method === 'email'
    },

    preferredMethodIsApp () {
      return this.user.two_factor_method_preferred === 'app'
    }
  },

  async created () {
    this.method = this.user.two_factor_method_enabled

    if (this.enabledMethodIsEmail) {
      await this.sendTwoFactorEmailCode()
    }
  },

  watch: {
    method () {
      if (this.$refs.validation) {
        this.$refs.validation.reset()
      }
    }
  },

  methods: {
    ...authMethods,

    async submit () {
      const { code, method } = this

      await this.verifyTwoFactorCode({ code, method })

      await this.authorize()

      this.$router.push({ name: 'dashboard' })
    },

    async emailTwoFactorCode () {
      await this.sendTwoFactorEmailCode()

      this.method = 'email'

      await swal({
        icon: 'success',
        title: 'Verification Code Sent',
        text: 'A verification code as been sent to your account\'s email address. This code will only be valid for 10 minutes.',
        buttons: {
          confirm: { text: 'OK' }
        }
      })
    },

    async logout () {
      await this.logout()

      this.$router.push({ name: 'login' })
    }
  }
}
</script>
